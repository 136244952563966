/**
 * Calculates the distance between two points.
 * @param {Object} pointA - The first point with x and y properties.
 * @param {Object} pointB - The second point with x and y properties.
 * @returns {number} The distance between the two points.
 */
/*
* A math toolkit for analyze.js
*/


// Takes 2 points (objects with x and y properties) and returns the distance between them
export function distance(pointA, pointB) {
    if (pointA.length === 2) return Math.sqrt((pointB[0] - pointA[0])**2 + (pointB[1] - pointA[1])**2);
    else return Math.sqrt((pointB[0] - pointA[0])**2 + (pointB[1] - pointA[1])**2 + (pointB[2] - pointA[2])**2);
}


// Takes 2 points (objects with x and y properties) and returns a vector from pointA to pointB
export function vector(pointA, pointB) {
    if (pointA.length === 2) return [pointB[0] - pointA[0], pointB[1] - pointA[1]];
    else return [pointB[0] - pointA[0], pointB[1] - pointA[1], pointB[2] - pointA[2]];
}


export function flipVector(vec) {
    if (vec.length === 2) {
        return [-1*vec[0], -1*vec[1]]; 
    }
    return [-1*vec[0], -1*vec[1], -1*vec[2]];
}


// Takes 2 vectors and returns the dot product
export function dot(vectorA, vectorB) {
    if (vectorA.length === 2) return vectorA[0] * vectorB[0] + vectorA[1] * vectorB[1];
    else return vectorA[0] * vectorB[0] + vectorA[1] * vectorB[1] + vectorA[2] * vectorB[2];
}


// Takes 2 points (objects with x and y properties) and returns an equidistant point between them
export function midpoint(pointA, pointB) {
    if (pointA.length === 2) return [(pointA[0] + pointB[0])/ 2, (pointA[1] + pointB[1]) / 2 ];
    else return [(pointA[0] + pointB[0])/ 2, (pointA[1] + pointB[1]) / 2, (pointA[2] + pointB[2])/2 ];
}


// Takes a vector and returns its magnitude
export function magnitude(vec) {
    if (vec.length === 2) return Math.sqrt(vec[0]**2 + vec[1]**2);
    else return Math.sqrt(vec[0]**2 + vec[1]**2 + vec[2]**2);
}


// Takes 2 vectors and returns the angle between them
export function angle(vec1, vec2) {
    const m1 = magnitude(vec1);
    const m2 = magnitude(vec2);
    const d = dot(vec1, vec2);
    if ((m1 > 0 && m2 > 0) && (Math.abs(d / (m1 * m2)) < 1)) {
        return ((Math.acos(d / (m1 * m2)) * 180/Math.PI));
    } else {
        return 0;
    }
}


// Checks whether two values are not within a certain range of each other
export function compare(ref, run, sig) {
    return (Math.abs(ref - run) > sig);
}


// Takes a vector and returns its unit vector
export function normalize(vec) {
    const m = magnitude(vec);
    return [vec[0] / m, vec[1] / m];
}


// Rounds a number to a certain number of decimal places
export function round(num, places=2) {
    return Math.round(num * 10**places) / 10**places;
}


// Takes 2 points and returns its slope
export function slope(pointA, pointB) {
    return (pointB['y'] - pointA['y']) / (pointB['x'] - pointA['x']);
}


// Takes a vector and returns its slope
export function slopeVec(vec) {
    return vec[1] / vec[0];
}


// Takes in two points, each of the form [val1, val2], and returns the slope of the line between them
export function derivative(b, a) {
    if (a[0] - b[0] === 0) { 
        return 100;
    } else {
        return (a[1] - b[1]) / (a[0] - b[0]);
    }
}


/* Approximates instantaneous rate of change at a point from an array of previous points
using exponential smoothing (alpha is the smoothing factor) */
export function estDeriv(xPoints, yPoints, alpha) {
    let weightedSlopeSum = 0;
    let scale = 0;
    const P = xPoints.length;

    for (let i = 0; i < P - 1; i++) {
        weightedSlopeSum += (Math.pow(alpha, P - i - 1) * derivative([xPoints[i], yPoints[i]], [xPoints[P - 1], yPoints[P - 1]]));
        scale += Math.pow(alpha, P - i - 1);
    }

    return weightedSlopeSum / scale;
}


/**
 * Calculates the sigmoid function.
 * 
 * @param {number} a - The scaling factor.
 * @param {number} b - The exponent factor.
 * @returns {function} - A function that calculates the sigmoid value for a given input.
 */
export function sigmoid(a, b) {
    return (x) => {
        return Math.E**(-1 * (x / a)**b);
    }
}


// Average magnitude of an array of numbers
export function avgAbs(array) {
    let sum = 0;
    for (let i = 0; i < array.length; i++) {
        sum += Math.abs(array[i]);
    }
    return sum / array.length;
}


// Flips a vector in x and y directions
export function flip(vec) {
    if (vec.length === 3) return [-vec[0], -vec[1], vec[2]];
    else return [-vec[0], -vec[1]];
}


// Collects useful keypoints from pose
export function get3DKeypoints(pose) {
    return {
         nose: [pose[0].x, pose[0].y, pose[0].z],
         leftEye: [pose[2].x, pose[2].y, pose[2].z],
         rightEye: [pose[5].x, pose[5].y, pose[5].z],
         leftEar: [pose[7].x, pose[7].y, pose[7].z],
         rightEar: [pose[8].x, pose[8].y, pose[8].z],
         leftShoulder: [pose[11].x, pose[11].y, pose[11].z],
         rightShoulder: [pose[12].x, pose[12].y, pose[12].z],
         leftElbow: [pose[13].x, pose[13].y, pose[13].z],
         rightElbow: [pose[14].x, pose[14].y, pose[14].z],
         leftWrist: [pose[15].x, pose[15].y, pose[15].z],
         rightWrist: [pose[16].x, pose[16].y, pose[16].z],
         leftHip: [pose[23].x, pose[23].y, pose[23].z],
         rightHip: [pose[24].x, pose[24].y, pose[24].z],
         leftKnee: [pose[25].x, pose[25].y, pose[25].z],
         rightKnee: [pose[26].x, pose[26].y, pose[26].z],
         leftAnkle: [pose[27].x, pose[27].y, pose[27].z],
         rightAnkle: [pose[28].x, pose[28].y, pose[28].z],
         //33
         midShoulder: midpoint([pose[11].x, pose[11].y, pose[11].z], [pose[12].x, pose[12].y, pose[12].z]),
         //34
         midHip: midpoint([pose[23].x, pose[23].y, pose[23].z], [pose[24].x, pose[24].y, pose[24].z]),          
         //35
         midAnkle: midpoint([pose[27].x, pose[27].y, pose[27].z], [pose[28].x, pose[28].y, pose[28].z])      
    };
}

// Collects useful flat keypoints from flat pose
export function get2DKeypoints(pose) {
    return {
         nose: [pose[0].x, pose[0].y],
         leftEye: [pose[1].x, pose[1].y],
         rightEye: [pose[2].x, pose[2].y],
         leftEar: [pose[3].x, pose[3].y],
         rightEar: [pose[4].x, pose[4].y],
         leftShoulder: [pose[5].x, pose[5].y],
         rightShoulder: [pose[6].x, pose[6].y],
         leftElbow: [pose[7].x, pose[7].y],
         rightElbow: [pose[8].x, pose[8].y],
         leftWrist: [pose[9].x, pose[9].y],
         rightWrist: [pose[10].x, pose[10].y],
         leftHip: [pose[11].x, pose[11].y],
         rightHip: [pose[12].x, pose[12].y],
         leftKnee: [pose[13].x, pose[13].y],
         rightKnee: [pose[14].x, pose[14].y],
         leftAnkle: [pose[15].x, pose[15].y],
         rightAnkle: [pose[16].x, pose[16].y],
         //17
         midShoulder: midpoint([pose[5].x, pose[5].y], [pose[6].x, pose[6].y]),
         //18
         midHip: midpoint([pose[11].x, pose[11].y], [pose[12].x, pose[12].y]),          
         //19
         midAnkle: midpoint([pose[15].x, pose[15].y], [pose[16].x, pose[16].y])      
    };
}



export function getKeyvectors(keypoints) {
    return { 
        // face
        eyes: vector(keypoints.leftEye, keypoints.rightEye),
        ears: vector(keypoints.leftEar, keypoints.rightEar),
        leftSideVector: vector(keypoints.leftEar, keypoints.nose),
        rightSideVector: vector(keypoints.rightEar, keypoints.nose),
        // arms
        left_shoulder_elbow: vector(keypoints.leftShoulder, keypoints.leftElbow),
        right_shoulder_elbow: vector(keypoints.rightShoulder, keypoints.rightElbow),
        left_elbow_wrist: vector(keypoints.leftElbow, keypoints.leftWrist),
        right_elbow_wrist: vector(keypoints.rightElbow, keypoints.rightWrist),
        // legs
        left_hip_knee: vector(keypoints.leftHip, keypoints.leftKnee),
        right_hip_knee: vector(keypoints.rightHip, keypoints.rightKnee),
        left_knee_ankle: vector(keypoints.leftKnee, keypoints.leftAnkle),
        right_knee_ankle: vector(keypoints.rightKnee, keypoints.rightAnkle),
        // centers
        head_midShoulder: vector(keypoints.nose, keypoints.midShoulder),
        midShoulder_midHip: vector(keypoints.midShoulder, keypoints.midHip),
        // shoulder --> center
        left_shoulder_midShoulder: vector(keypoints.leftShoulder, keypoints.midShoulder),
        right_shoulder_midShoulder: vector(keypoints.rightShoulder, keypoints.midShoulder),
        // shoulder to shoulder
        right_shoulder_left_shoulder: vector(keypoints.rightShoulder, keypoints.leftShoulder),
        // hip --> center
        left_hip_midHip: vector(keypoints.leftHip, keypoints.midHip),
        right_hip_midHip: vector(keypoints.rightHip, keypoints.midHip),
        // hip to hip
        right_hip_left_hip: vector(keypoints.rightHip, keypoints.leftHip),
        // central
        midShoulder_head: vector(keypoints.midShoulder, keypoints.nose),
        // orientation check
        midHip_head: vector(keypoints.midHip, keypoints.nose),
        midHip_midAnkle: vector(keypoints.midHip, keypoints.midAnkle),
        //frame
        left_shoulder_hip: vector(keypoints.leftShoulder, keypoints.leftHip),
        right_shoulder_hip: vector(keypoints.rightShoulder, keypoints.rightHip),
    };
}


export function getKeyangles(keyvectors) {
    return {
        // arm
        left_shoulder_elbow_wrist: angle(keyvectors.left_elbow_wrist, flipVector(keyvectors.left_shoulder_elbow)),
        right_shoulder_elbow_wrist: angle(keyvectors.right_elbow_wrist, flipVector(keyvectors.right_shoulder_elbow)),
        // leg
        left_hip_knee_ankle: angle(keyvectors.left_knee_ankle, flipVector(keyvectors.left_hip_knee)),
        right_hip_knee_ankle: angle(keyvectors.right_knee_ankle, flipVector(keyvectors.right_hip_knee)),
        // 3D shoulder elevation
        left_midShoulder_shoulder_elbow: angle(keyvectors.left_shoulder_midShoulder, keyvectors.left_shoulder_elbow),
        right_midShoulder_shoulder_elbow: angle(keyvectors.right_shoulder_midShoulder, keyvectors.right_shoulder_elbow),
        // 2D shoulder elevation
        left_hip_shoulder_elbow: angle(keyvectors.left_shoulder_elbow, keyvectors.left_shoulder_hip),
        right_hip_shoulder_elbow: angle(keyvectors.right_shoulder_elbow, keyvectors.right_shoulder_hip),
        //3D leg stance
        left_midHip_hip_knee: angle(keyvectors.left_hip_midHip, keyvectors.left_hip_knee),
        right_midHip_hip_knee: angle(keyvectors.right_hip_midHip, keyvectors.right_hip_knee),
        //2D leg stance
        left_knee_hip_shoulder: angle(keyvectors.left_hip_knee, flipVector(keyvectors.left_shoulder_hip)),
        right_knee_hip_shoulder: angle(keyvectors.right_hip_knee, flipVector(keyvectors.right_shoulder_hip)),
        // central
        centralHead: angle(keyvectors.midShoulder_head, keyvectors.midShoulder_midHip),
        centralBack: angle(flipVector(keyvectors.midShoulder_midHip), keyvectors.midHip_midAnkle)
    };
}

export function get2DOrientation(keyvectors) {
    let slope = Math.abs(keyvectors.midShoulder_midHip[1])/Math.abs(keyvectors.midShoulder_midHip[0]);
    if (slope > 1) {
        return 1
    }
    return 0;
}

export function get3DOrientation(keyvectors) {
    let height = Math.abs(keyvectors.midShoulder_midHip[2]);
    let base = Math.sqrt(keyvectors.midShoulder_midHip[0]**2 + keyvectors.midShoulder_midHip[1]**2);
    let slope = height/base;
    if (slope > 1) {
        return 1
    }
    return 0;

}


export function similar(angles1, angles2) {
    const sig = 20; //average sig is 15
    return (compare_upper3D(angles1, angles2, sig).length === 0 &&
        compare_lower3D(angles1, angles2, sig).length === 0 &&
        compare_central3D(angles1, angles2, sig).length === 0);
}


export function checkArrays(angles, total) {
    //We create a partition on angles through equivalence classes based on the relation similar
    let equivClasses = [{index: 0, angles: angles[0], count: 1}]; //every element is in the form {index (first occurence), angles, count}
    for (let i = 1; i < angles.length; i++) {
        for (let j = 0; j < equivClasses.length; j++) {
            if (similar(angles[i], equivClasses[j].angles)) {
                equivClasses[j].count++;
                j = equivClasses.length;
            }
            if (j === equivClasses.length - 1) {
                equivClasses.push({index: i, angles: angles[i], count: 1});
            }
        }
    }
    let max = 0;
    for (let i = 0; i < equivClasses.length; i++) {
        if (equivClasses[i].count > max) {
            max = equivClasses[i].count;
        }
        if (equivClasses[i].count > .85 * total) {
            return {angles: equivClasses[i].angles, percentage: 100};
        }
    }
    return {angles: null, percentage: max/(.85*total)};
}

//adjust individual angles if their scores are too low
export function editAngle(pose, keyangles) {
    if (pose[13].score < .5) {
        if (pose[14].score > .5) {
            keyangles.left_shoulder_elbow_wrist = keyangles.right_shoulder_elbow_wrist;
        }    
    }
    if (pose[14].score < .5) {
        if (pose[13].score > .5) {
            keyangles.right_shoulder_elbow_wrist = keyangles.left_shoulder_elbow_wrist;
        }    
    }
    if (pose[25].score < .5) {
        if (pose[26].score > .5) {
            keyangles.left_hip_knee_ankle = keyangles.right_hip_knee_ankle;

        }
    }
    if (pose[26].score < .5) {
        if (pose[25].score > .5) {
            keyangles.right_hip_knee_ankle = keyangles.left_hip_knee_ankle;
        }
    }
    if (pose[11].score < .5) {
        if (pose[12].score > .5) {
            keyangles.left_midShoulder_shoulder_elbow = keyangles.right_midShoulder_shoulder_elbow;
            keyangles.left_hip_shoulder_elbow = keyangles.right_hip_shoulder_elbow;
        }    
    }
    if (pose[12].score < .5) {
        if (pose[11].score > .5) {
            keyangles.right_midShoulder_shoulder_elbow = keyangles.left_midShoulder_shoulder_elbow;
            keyangles.right_hip_shoulder_elbow = keyangles.left_hip_shoulder_elbow;
        }    
    }
    if (pose[23].score < .5) {
        if (pose[24].score > .5) {
            keyangles.left_midHip_hip_knee = keyangles.right_midHip_hip_knee;
            keyangles.left_knee_hip_shoulder = keyangles.right_knee_hip_shoulder;
        }    
    }
    if (pose[24].score < .5) {
        if (pose[23].score > .5) {
            keyangles.right_midHip_hip_knee = keyangles.left_midHip_hip_knee;
            keyangles.right_knee_hip_shoulder = keyangles.left_knee_hip_shoulder;
        }    
    }
    return keyangles;
}


//adjust individual angles if their scores are too low
export function editAngle2D(pose, keyangles) {
    if (pose[7].score < .5) {
        if (pose[8].score > .5) {
            keyangles.left_shoulder_elbow_wrist = keyangles.right_shoulder_elbow_wrist;
        }    
    }
    if (pose[8].score < .5) {
        if (pose[7].score > .5) {
            keyangles.right_shoulder_elbow_wrist = keyangles.left_shoulder_elbow_wrist;
        }    
    }
    if (pose[13].score < .5) {
        if (pose[14].score > .5) {
            keyangles.left_hip_knee_ankle = keyangles.right_hip_knee_ankle;
        }
    }
    if (pose[14].score < .5) {
        if (pose[13].score > .5) {
            keyangles.right_hip_knee_ankle = keyangles.left_hip_knee_ankle;
        }
    }
    if (pose[5].score < .5) {
        if (pose[6].score > .5) {
            keyangles.left_midShoulder_shoulder_elbow = keyangles.right_midShoulder_shoulder_elbow;
            keyangles.left_hip_shoulder_elbow = keyangles.right_hip_shoulder_elbow;
        }    
    }
    if (pose[6].score < .5) {
        if (pose[5].score > .5) {
            keyangles.right_midShoulder_shoulder_elbow = keyangles.left_midShoulder_shoulder_elbow;
            keyangles.right_hip_shoulder_elbow = keyangles.left_hip_shoulder_elbow;
        }    
    }
    if (pose[11].score < .5) {
        if (pose[12].score > .5) {
            keyangles.left_midHip_hip_knee = keyangles.right_midHip_hip_knee;
            keyangles.left_knee_hip_shoulder = keyangles.right_knee_hip_shoulder;
        }    
    }
    if (pose[12].score < .5) {
        if (pose[11].score > .5) {
            keyangles.right_midHip_hip_knee = keyangles.left_midHip_hip_knee;
            keyangles.right_knee_hip_shoulder = keyangles.left_knee_hip_shoulder;
        }    
    }
    return keyangles;
}

//determine if we need to use 2D or 3D points 
//takes in 2D keypoints 
//true if we stick with 2D, false if we need 3D
export function hybridFunction(pose) {
    const keypoints = get2DKeypoints(pose);
    const shoulders = magnitude(vector(keypoints.rightShoulder, keypoints.leftShoulder));
    const hips = magnitude(vector(keypoints.rightHip, keypoints.leftHip));
    const ref = magnitude(vector(keypoints.midShoulder, keypoints.midHip));
    return ((shoulders < ref * .25) && (hips < ref * .25))
}
// collect keyangles from 3d blaze pose
export function compile3D(pose) {
    const keypoints = get3DKeypoints(pose);
    const keyvectors = getKeyvectors(keypoints);
    let keyangles = getKeyangles(keyvectors);
    keyangles = editAngle(pose, keyangles);
    const orientation = get2DOrientation(keyvectors);
    return {angles: keyangles, orientation};
}

// collect keyangles from 2d movenet pose
export function compile2D(pose) {
    const keypoints = get2DKeypoints(pose);
    const keyvectors = getKeyvectors(keypoints);
    let keyangles = getKeyangles(keyvectors);
    keyangles = editAngle2D(pose, keyangles);
    const orientation = get2DOrientation(keyvectors);
    return {angles: keyangles, orientation};
}


// compare upper body angles given ref and run angles
export function compare_upper3D(refangles, runangles, sig) {
    let upperfeedback = [];
    if (compare(refangles.left_shoulder_elbow_wrist, runangles.left_shoulder_elbow_wrist, sig)) {
        upperfeedback.push([11, 13, 15]);
    }
    if (compare(refangles.right_shoulder_elbow_wrist, runangles.right_shoulder_elbow_wrist, sig)) {
        upperfeedback.push([12, 14, 16]);
    }
    if (compare(refangles.left_midShoulder_shoulder_elbow, runangles.left_midShoulder_shoulder_elbow, sig)) {
        upperfeedback.push([33, 11, 13]);
    }
    if (compare(refangles.right_midShoulder_shoulder_elbow, runangles.right_midShoulder_shoulder_elbow, sig)) {
        upperfeedback.push([33, 12, 14]);
    }
    return upperfeedback;
}

export function compare_upper_right_3D(refangles, runangles, sig) {
    let upperfeedback = [];
    if (compare(refangles.right_shoulder_elbow_wrist, runangles.right_shoulder_elbow_wrist, sig)) {
        upperfeedback.push([12, 14, 16]);
    }
    if (compare(refangles.right_midShoulder_shoulder_elbow, runangles.right_midShoulder_shoulder_elbow, sig)) {
        upperfeedback.push([33, 12, 14]);
    }
    return upperfeedback;
}

export function compare_upper_left_3D(refangles, runangles, sig) {
    let upperfeedback = [];
    if (compare(refangles.left_shoulder_elbow_wrist, runangles.left_shoulder_elbow_wrist, sig)) {
        upperfeedback.push([11, 13, 15]);
    }
    if (compare(refangles.left_midShoulder_shoulder_elbow, runangles.left_midShoulder_shoulder_elbow, sig)) {
        upperfeedback.push([33, 11, 13]);
    }
    return upperfeedback;
}


//compare lower body angles given ref and run angles
export function compare_lower3D(refangles, runangles, sig) {
    let lowerfeedback = [];
    if (compare(refangles.left_hip_knee_ankle, runangles.left_hip_knee_ankle, sig)) {
        lowerfeedback.push([23, 25, 27]);
    }
    
    if (compare(refangles.right_hip_knee_ankle, runangles.right_hip_knee_ankle, sig)) {
        lowerfeedback.push([24, 26, 28]);
    }
    if (compare(refangles.left_midHip_hip_knee, runangles.left_midHip_hip_knee, sig)) {
        lowerfeedback.push([34, 23, 25]);
    }
    if (compare(refangles.right_midHip_hip_knee, runangles.right_midHip_hip_knee, sig)) {
        lowerfeedback.push([34, 24, 26]);
    }
    return lowerfeedback;
}

export function compare_lower_right_3D(refangles, runangles, sig) {
    let lowerfeedback = [];
    if (compare(refangles.right_hip_knee_ankle, runangles.right_hip_knee_ankle, sig)) {
        lowerfeedback.push([24, 26, 28]);
    }
    if (compare(refangles.right_midHip_hip_knee, runangles.right_midHip_hip_knee, sig)) {
        lowerfeedback.push([34, 24, 26]);
    }
    return lowerfeedback;
}

//compare lower body angles given ref and run angles
export function compare_lower_left_3D(refangles, runangles, sig) {
    let lowerfeedback = [];
    if (compare(refangles.left_hip_knee_ankle, runangles.left_hip_knee_ankle, sig)) {
        lowerfeedback.push([23, 25, 27]);
    }
    
    if (compare(refangles.left_midHip_hip_knee, runangles.left_midHip_hip_knee, sig)) {
        lowerfeedback.push([34, 23, 25]);
    }

    return lowerfeedback;
}



//compare central body angles given ref and run angles
export function compare_central3D(refangles, runangles, sig) {
    let centralfeedback = [];
    // if (compare(refangles.centralHead, runangles.centralHead, sig)) {
    //     centralfeedback.push([0, 33, 34]);
    // }
    if (compare(refangles.centralBack, runangles.centralBack, sig)) {
        centralfeedback.push([33, 34, 35]);
    }
    return centralfeedback;
}

// compare upper body angles given ref and run angles
export function compare_upper2D(refangles, runangles, sig) {
    let upperfeedback = [];
    if (compare(refangles.left_shoulder_elbow_wrist, runangles.left_shoulder_elbow_wrist, sig)) { 
        upperfeedback.push([5, 7, 9]);
    }
    if (compare(refangles.right_shoulder_elbow_wrist, runangles.right_shoulder_elbow_wrist, sig)) {
        upperfeedback.push([6, 8, 10]);
    }
    if (compare(refangles.left_hip_shoulder_elbow, runangles.left_hip_shoulder_elbow, sig)) {
        upperfeedback.push([11, 5, 7]);
    }
    if (compare(refangles.right_hip_shoulder_elbow, runangles.right_hip_shoulder_elbow, sig)) {
        upperfeedback.push([12, 6, 8]);
    }

    return upperfeedback;
}

export function compare_upper_right_2D(refangles, runangles, sig) {
    let upperfeedback = [];
    if (compare(refangles.right_shoulder_elbow_wrist, runangles.right_shoulder_elbow_wrist, sig)) {
        upperfeedback.push([6, 8, 10]);
    }
    if (compare(refangles.right_hip_shoulder_elbow, runangles.right_hip_shoulder_elbow, sig)) {
        upperfeedback.push([12, 6, 8]);
    }

    return upperfeedback;
}

export function compare_upper_left_2D(refangles, runangles, sig) {
    let upperfeedback = [];
    if (compare(refangles.left_shoulder_elbow_wrist, runangles.left_shoulder_elbow_wrist, sig)) { 
        upperfeedback.push([5, 7, 9]);
    }
    if (compare(refangles.left_hip_shoulder_elbow, runangles.left_hip_shoulder_elbow, sig)) {
        upperfeedback.push([11, 5, 7]);
    }
    return upperfeedback;
}


//compare lower body angles given ref and run angles
export function compare_lower2D(refangles, runangles, sig) {
    let lowerfeedback = [];
    if (compare(refangles.left_hip_knee_ankle, runangles.left_hip_knee_ankle, sig)) {
        lowerfeedback.push([11, 13, 15]);
    }
    if (compare(refangles.right_hip_knee_ankle, runangles.right_hip_knee_ankle, sig)) {
        lowerfeedback.push([12, 14, 16]);
    }
    if (compare(refangles.left_knee_hip_shoulder, runangles.left_knee_hip_shoulder, sig)) {
        lowerfeedback.push([13, 11, 5]);
    }
    if (compare(refangles.right_knee_hip_shoulder, runangles.right_knee_hip_shoulder, sig)) {
        lowerfeedback.push([14, 12, 6]);
    }
    return lowerfeedback;
}

export function compare_lower_right_2D(refangles, runangles, sig) {
    let lowerfeedback = [];
    
    if (compare(refangles.right_hip_knee_ankle, runangles.right_hip_knee_ankle, sig)) {
        lowerfeedback.push([12, 14, 16]);
        console.log(refangles.right_hip_knee_ankle);
        console.log(runangles.right_hip_knee_ankle);
    }
    if (compare(refangles.right_knee_hip_shoulder, runangles.right_knee_hip_shoulder, sig)) {
        lowerfeedback.push([14, 12, 6]);
    }
    return lowerfeedback;
}

//compare lower body angles given ref and run angles
export function compare_lower_left_2D(refangles, runangles, sig) {
    let lowerfeedback = [];
    if (compare(refangles.left_hip_knee_ankle, runangles.left_hip_knee_ankle, sig)) {
        lowerfeedback.push([11, 13, 15]);
    }
    if (compare(refangles.left_knee_hip_shoulder, runangles.left_knee_hip_shoulder, sig)) {
        lowerfeedback.push([13, 11, 5]);
    }
    return lowerfeedback;
}


//compare central body angles given ref and run angles
export function compare_central2D(refangles, runangles, sig) {
    let centralfeedback = [];
    // if (compare(refangles.centralHead, runangles.centralHead, sig)) {
    //     centralfeedback.push([0, 17, 18]);
    // }
    if (compare(refangles.centralBack, runangles.centralBack, sig)) {
        centralfeedback.push([17, 18, 19]);
    }
    return centralfeedback;
}